import React from "react";
import { withRouter } from "react-router-dom";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import FrameExerciciosDigitais from "../components/frameExerciciosDigitais";
import NotasExerciciosExtrasService from "../app/service/notasExerciciosExtras";
import LocalStorageService from "../app/service/localstorageService";

class PlayerExerciciosDigitais extends React.Component {
  
  
  state = {
    urlAula: "",
    gravouNota: false,
  };


  componentDidMount() {
      
    const cursoAtual = LocalStorageService.obterItem("_curso_atual");

    console.log(cursoAtual);
   
      this.setState({
        urlAula: `${cursoAtual.caminho_exerc_dig}/aula${cursoAtual.aulaAtual}/index.html`,
      });
    
  }

  componentWillUnmount() {
    this.setState({ gravouNota: false });
  }

  voltar = () => {
    this.props.history.push("/home");
  };

  gravarNota = (nota) => {
    if (!this.state.gravouNota) {
      if (nota >= 60) {
        this.setState({ alunoAprovado: true, modalFimAula: true });
      } else {
        this.setState({ alunoAprovado: false, modalFimAula: true });
      }

      const service = new NotasExerciciosExtrasService();
      const curso = LocalStorageService.obterItem("_curso_atual");
      const params = this.props.match.params;
      const dto = { nota, aula: curso.aulaAtual, curso: curso.id };
      service
        .gravarNota(dto)
        .then((response) => {
          console.log("Nota exercício registrada");
        })
        .catch((erro) => {
          console.error("Erro ", erro.response);
        });
      this.setState({ gravouNota: true });
    }
  };

  render() {
    return (
      <>
        <FrameExerciciosDigitais
          urlAula={this.state.urlAula}
          history={this.props.history}
          gravarNota={(nota) => this.gravarNota(nota)}
        />
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: 1 / 2, right: 3, height: 10 }}
          icon={<ExitToAppIcon />}
        >
          <SpeedDialAction
            key={"Voltar para Home"}
            icon={<MeetingRoomIcon />}
            tooltipTitle={"Voltar"}
            onClick={this.voltar}
          />
        </SpeedDial>
      </>
    );
  }
}

export default withRouter(PlayerExerciciosDigitais);
